import { REQUIRED } from '@/util/validation-rules';
import { DATE_DEFAULT_REGXP, HOUR_DEFAULT_REGXP } from "@/util/const";
import dayjs from "dayjs";
import { DDMMYYYYFormatting } from "@/util";

export default class OnlineAccessValidator {
  constructor(data, validator) {
    this.data = data;
    this.validator = validator;
  }

  validate() {
    this.validator.resetFields();
    return this.isValid();
  }

  isValid() {
    this.validatePackageName();
    this.validatePackageStartDate();
    this.validatePackageStartTime();
    this.validatePackageEndDate();
    this.validatePackageEndTime();
    this.validatePackageExamAtDate()
    this.validatePackageExamAtTime()
    return this.validator.isValid();
  }

  validatePackageName() {
    if (!this.validateRequired('name', this.data.name));
  }

  validatePackageStartDate() {
    if (!this.validateRequired('start_date', this.data.start_date));
    if (!(DATE_DEFAULT_REGXP.test(this.data.start_date))) this.validator.invalidField('start_date', 'Date should be in DD-MM-YYYY format');
  }

  validatePackageStartTime() {
    if (!this.validateRequired('start_time', this.data.start_time));
    if (!(HOUR_DEFAULT_REGXP.test(this.data.start_time))) this.validator.invalidField('start_time', 'Time should be in 14:00 format');
  }

  validatePackageEndDate() {
    if (!this.validateRequired('end_date', this.data.end_date));
    if (!(DATE_DEFAULT_REGXP.test(this.data.end_date))) this.validator.invalidField('end_date', 'Date should be in DD-MM-YYYY format');
    const date1 = dayjs(DDMMYYYYFormatting(this.data.end_date));
    const date2 = dayjs(DDMMYYYYFormatting(this.data.start_date));
    let diff = date2.diff(date1);
    if (diff >= 0) this.validator.invalidField('end_date', 'End date should be greater than start date');
  }

  validatePackageEndTime() {
    if (!this.validateRequired('end_time', this.data.end_time));
    if (!(HOUR_DEFAULT_REGXP.test(this.data.end_time))) this.validator.invalidField('end_time', 'Time should be in 14:00 format');
  }

  validatePackageExamAtDate() {
    // if (!this.validateRequired('package_exams_date', this.data.package_exams_date));
    if(!this.data.package_exams_date) return
    if (!(DATE_DEFAULT_REGXP.test(this.data.package_exams_date))) this.validator.invalidField('package_exams_date', 'Date should be in DD-MM-YYYY format');
    const date1 = dayjs(DDMMYYYYFormatting(this.data.package_exams_date));
    const date2 = dayjs(DDMMYYYYFormatting(this.data.end_date));
    let diff = date2.diff(date1);
    if (diff < 0) this.validator.invalidField('package_exams_date', 'Materials end date should be less than end date');
  }

  validatePackageExamAtTime() {
    // if (!this.validateRequired('package_exams_time', this.data.package_exams_time));
    if(!this.data.package_exams_time) return
    if (!(HOUR_DEFAULT_REGXP.test(this.data.package_exams_time))) this.validator.invalidField('package_exams_time', 'Time should be in 14:00 format');
  }

  validateDate(field, date) {
    if (!this.validateRequired('date', this.data.date));
    if(!this.data.package_exams_date) return
  }

  validateRequired(field, data) {
    let match = REQUIRED(data);
    if (match === true) return true;
    this.validator.invalidField(field, match);
    return false;
  }
}
