<template lang="pug">
  app-dialog(v-model="showing" title="Edit Nutheorie online access" @click:outside="clear" width="700")
    template(#dialog-action)
      slot(name="activator" :open="open")
        v-btn(@click="open" block :disabled="disabled").mt-3 Edit access
    template(#dialog-body)
      v-stepper(v-model='step' vertical).stepper
        v-stepper-step(
          :complete='step > 1'
          step='1'
          editable
          :rules="[() => !formErrors.hasError('name')]"
        ) Select package
          small {{ formData.name || '' }}
        v-stepper-content(step='1')
          div.step-content(v-if="model")
            v-row
              v-col(cols=6)
                filter-autocomplete(
                  :getItems="packagesByLicenseType"
                  item-value="name"
                  item-text="name"
                  v-model="formData.name"
                  label="Package name"
                  :error="formErrors.hasError('name')"
                  :error-messages="formErrors.fields.name"
                  @change="formErrors.validField('name')"
                )

            v-btn(color='primary' @click='step = 2' :disabled="!formData.name") Continue

        v-stepper-step(
          :complete='step > 2'
          step='2'
          editable
          :rules="[() => !formErrors.hasError('start_date') && !formErrors.hasError('start_time')]"
        ) Select start datetime
          small {{ formData.start_date || '' }} {{ formData.start_time || '' }}
        v-stepper-content(step='2')
          .step-content
            v-row
              v-col(cols=6)
                field-date-picker(
                  v-model="formData.start_date"
                  label="Select start date"
                  :hide-details="false"
                  :error="formErrors.hasError('start_date')"
                  :error-messages="formErrors.fields.start_date"
                )
              v-col(cols=6)
                field-date-picker(
                  v-model="formData.start_time"
                  label="Select start time"
                  :hide-details="false"
                  timepicker
                  :error="formErrors.hasError('start_time')"
                  :error-messages="formErrors.fields.start_time"
                )

            v-btn(color="primary" @click='step = 3' :disabled="!formData.start_date || !formData.start_time") Continue

        v-stepper-step(
          :complete='step > 3'
          step='3'
          editable
          :rules="[() => !formErrors.hasError('end_date') && !formErrors.hasError('end_time')]"
        ) Select end datetime
          small {{ formData.end_date || '' }} {{ formData.end_time || '' }}
        v-stepper-content(step='3')
          .step-content
            v-row
              v-col(cols=6)
                field-date-picker(
                  v-model="formData.end_date"
                  label="Select end date"
                  :hide-details="false"
                  :error="formErrors.hasError('end_date')"
                  :error-messages="formErrors.fields.end_date"
                  @input="preselectPackageExamAt"
                )
              v-col(cols=6)
                field-date-picker(
                  v-model="formData.end_time"
                  label="Select end time"
                  :hide-details="false"
                  timepicker
                  :error="formErrors.hasError('end_time')"
                  :error-messages="formErrors.fields.end_time"
                  @input="preselectPackageExamAt"
                )
            v-btn(color="primary" @click="step = 4" :disabled="!formData.end_date || !formData.end_time") Continue

        v-stepper-step(step='4' :complete='step > 4' editable) Online paid amount
        v-stepper-content(step='4')
          .step-content
            v-text-field(v-model="formData.package_paid_amount" label="Online paid amount" :readonly="!formData.has_package")
            v-btn(color="primary" @click="step = 5") Continue

        v-stepper-step(step='5' editable)  Materials end date
          small {{ formData.package_exams_date || '' }} {{ formData.package_exams_time || '' }}
        v-stepper-content(step='5')
          .step-content
            v-row
              v-col(cols=6)
                field-date-picker(
                  v-model="formData.package_exams_date"
                  label="Select end date"
                  :hide-details="false"
                  :error="formErrors.hasError('package_exams_date')"
                  :error-messages="formErrors.fields.package_exams_date"
                )
              v-col(cols=6)
                field-date-picker(
                  v-model="formData.package_exams_time"
                  label="Select end time"
                  :hide-details="false"
                  timepicker
                  :error="formErrors.hasError('package_exams_time')"
                  :error-messages="formErrors.fields.package_exams_time"
                )

      .stepper__footer
        span By doing these changes you will give access to a candidate. Are you sure you want to proceed?
    template(#dialog-footer)
      app-button(color="#f4f7f9" @click.native="clear" hide-shadow).mr-3
        span.text-label Cancel
      app-button(color="primary" @click.native="submit") Save
</template>

<script>
import FormErrors from '@/util/form-errors'
import { mapActions, mapGetters } from 'vuex'
import showingMixin from '@/mixins/showing.mixin'
import errorsMixin from '@/mixins/errors.mixin.js'
import permsMixin from '@/mixins/perms.mixin'
import Intersect from 'vue-intersect'
import onlineAccessValidator from '../core/onlineAccessValidator.js'
import { convertToDefaultDateFormat, convertToDefaultHourFormat, DDMMYYYYFormatting } from "@/util"
import CandidatesService from "@/app/admin/modules/candidates/core/candidates-service"

export default {
  name: 'CandidatesOnlineAccess',

  mixins: [showingMixin, permsMixin, errorsMixin],

  props: {
    disabled: Boolean,
    activatorText: {type: String, default: 'Edit access'},
    model: Object,
  },

  computed: {
    ...mapGetters({
      packagesList: 'packages/list'
    })
  },

  data: () => ({
    svc: new CandidatesService(),
    loading: false,
    formErrors: new FormErrors(),
    formData: {
      name: null,
      start_date: null,
      start_time: null,
      end_date: null,
      end_time: null,
      package_paid_amount: 0,
      package_exams_date: null,
      package_exams_time: null,
      has_package: false
    },
    step: 1
  }),

  methods: {
    ...mapActions({
      loadPackagesList: 'packages/list'
    }),

    async packagesByLicenseType(params) {
      const list = await this.loadPackagesList(params);
      return (list || []).filter(item => item.license_type === this.model?.licenseType)
    },

    open() {
      this.updateShowing(true)
    },

    close() {
      this.updateShowing(false)
    },

    async submit() {
      if (this.loading) return
      this.formErrors.resetFields()

      if (!this.validate()) {
        this.$notify({text: 'Invalid fields', type: 'error'})
        return
      }

      try {
        this.loading = true
        let _startDateTime = `${DDMMYYYYFormatting(this.formData.start_date)} ${this.formData.start_time}`
        let _endDateTime = `${DDMMYYYYFormatting(this.formData.end_date)} ${this.formData.end_time}`
        let _package_exams_only_at =  this.formData.package_exams_date ? `${DDMMYYYYFormatting(this.formData.package_exams_date)} ${this.formData.package_exams_time}`: ''
        const formData = {
          has_package: true,
          package_starting_at: this.$dates(_startDateTime, 'YYYY-MM-DD HH:mm').format(),
          package_ending_at: this.$dates(_endDateTime, 'YYYY-MM-DD HH:mm').format(),
          package_exams_only_at: this.$dates(_package_exams_only_at, 'YYYY-MM-DD HH:mm').format(),
          package_name: this.formData.name,
          package_paid_amount: this.formData.package_paid_amount
        }
        if(!_package_exams_only_at) formData.package_exams_only_at = null
        await this.svc.patch(this.model.ID, formData)
        this.$notify({text: 'Successfully updated', type: 'success'})
        this.close()
      } catch (error) {
        this.processError(error)
      } finally {
        this.loading = false
      }
    },

    validate() {
      let valid = new onlineAccessValidator(this.formData, this.formErrors)
      return valid.validate()
    },

    clear() {
      this.formData = {
        name: null,
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        package_exams_date: null,
        package_exams_time: null,
        package_paid_amount: 0,
        has_package: false
      }
      this.step = 1
      this.formErrors.resetFields()
      this.close()
    },

    preselectFormData() {
      if (this.model.package_name) this.formData.name = this.model.package_name
      if (this.model.has_package) this.formData.has_package = this.model.has_package
      if (this.model.package_starting_at) {
        this.formData.start_date = convertToDefaultDateFormat(this.model.package_starting_at)
        this.formData.start_time = convertToDefaultHourFormat(this.model.package_starting_at)
      }
      if (this.model.package_ending_at) {
        this.formData.end_date = convertToDefaultDateFormat(this.model.package_ending_at)
        this.formData.end_time = convertToDefaultHourFormat(this.model.package_ending_at)
      }
      if (this.model.package_exams_only_at) {
        this.formData.package_exams_date = convertToDefaultDateFormat(this.model.package_exams_only_at)
        this.formData.package_exams_time = convertToDefaultHourFormat(this.model.package_exams_only_at)
      }
      if (this.model.package_paid_amount) this.formData.package_paid_amount = this.model.package_paid_amount || 0
    },

    preselectEndDatetime() {
      let _package = this.packagesList.find(item => item.name === this.formData.name)
      if (!_package) return

      this.formData.end_date = this.$dates(new Date(DDMMYYYYFormatting(this.formData.start_date))).add(_package.duration, 'day').format('DD-MM-YYYY')
      this.formData.end_time = this.formData.start_time
    },

    preselectPackageExamAt() {
      if(!this.formData.package_exams_date) this.formData.package_exams_date = this.formData.end_date
      if(!this.formData.package_exams_time) this.formData.package_exams_time = this.formData.end_time
    }
  },

  watch: {
    showing: {
      handler: function() {
        if (!this.showing) {
          this.clear()
          return
        }
        this.preselectFormData()
      }
    },

    'step'() {
      if (!this.formData.has_package &&
        this.formData.name &&
        this.formData.start_date &&
        this.formData.start_time &&
        !this.formData.end_date &&
        !this.formData.end_time
      ) {
        this.preselectEndDatetime()
        
      }
    }
  },

  components: {
    appDialog: () => import('@/components/global/actions/BaseDialog.vue'),
    appButton: () => import('@/components/global/actions/BaseButton.vue'),
    appSelect: () => import('@/components/global/actions/BaseSelect.vue'),
    fieldDatePicker: () => import('@/components/global/FieldDatepicker.vue'),
    filterAutocomplete: () => import('@/components/global/FilterAutocomplete.vue'),
    Intersect
  }
}
</script>

<style lang="scss">
.stepper {
  box-shadow: none !important;
  padding-bottom: 0;

  &__footer {
    padding: 0 20px 10px 30px;
  }
}

.step-content {
  margin-bottom: 25px;
}
</style>
